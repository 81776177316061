import React, { useState } from "react";
import { useSelector } from "react-redux";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notify = () =>
  toast.success(
    "Thanks! Your submission is recieved successfully, Will reach you soon",
    {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      transition: Bounce,
    }
  );
toast.success("Thanks! for subscribing to our newsletter", {
  position: "bottom-center",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  transition: Bounce,
});

const addDataIntoAirtable = async (obj) => {
  const pat = process.env.REACT_APP_AIRTABLE_PAT;
  const base = process.env.REACT_APP_SAMSINFOSOFT_AIRTABLE_BASE;
  let data = {
    records: [
      {
        fields: obj,
      },
    ],
  };
  const URL = `https://api.airtable.com/v0/${base}/Contact%20Form`;
  await fetch(URL, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${pat}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      // console.log("Data : ", data);
      notify();
    });
  // console.log("Submitted data : ", submitted);
};

function ContactUs() {
  const isDay = useSelector((state) => state.colorTheme.day);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = () => {
    // console.log("Inside Display: ", email);
    const obj = {
      Email: email,
      Name: name,
      Subject: subject,
      Message: message,
    };
    if (email.length) {
      addDataIntoAirtable(obj);
    }
    setEmail("");
    setName("");
    setMessage("");
    setSubject("");
  };
  return (
    <div
      id="contact"
      className="z-0 w-full px-12 py-8 md:px-20"
      style={{ background: isDay ? "white" : "black" }}
    >
      <div
        className={`z-0 ${
          isDay ? "bg-white bg-opacity-20" : "bg-black bg-opacity-50"
        }`}
      >
        <h1 className={`text-3xl md:text-4xl font-semibold text-primary`}>
          Contact Us
        </h1>
        <div className="z-0 grid grid-cols-1 md:grid-cols-3 gap-8 my-8">
          <div
            className={`z-0 w-full p-8 flex flex-col justify-evenly items-center ${
              isDay ? "bg-white text-black" : "bg-[#151515] text-white"
            } rounded-lg shadow-lg ${
              isDay ? "shadow-gray-300" : "shadow-[#4F51E7]"
            }`}
          >
            <div id="address" className="mb-4">
              <h1 className="font-bold text-xl mb-4">Address:</h1>
              <p>123/3, Sector-K, Green Park Colony, Dhar Road</p>
              <p>Indore, Madhya Pradesh</p>
              <p> India - 452002</p>
            </div>
            <div className="mb-4">
              <h1 className="font-bold text-xl mb-4">Phone:</h1>
              <p>+91 8982413107</p>
            </div>
            <div className="z-0 mb-4">
              <h1 className="font-bold text-xl mb-4">Email:</h1>
              <a href="mailto:info@samsinfosoft.com" className="z-0">
                <p className="z-0">info@samsinfosoft.com</p>
              </a>
            </div>
          </div>

          <section
            className={`z-0 md:col-span-2 ${
              isDay ? "bg-white text-black" : "bg-[#151515] text-white"
            } rounded-lg shadow-lg ${
              isDay ? "shadow-gray-300" : "shadow-[#4F51E7]"
            }`}
          >
            <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
              <form action="#" className="space-y-8">
                <div className="z-0 grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <input
                      value={name}
                      onInput={(e) => {
                        setName(e.target.value);
                      }}
                      type="text"
                      id="name"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div>
                    <input
                      value={email}
                      onInput={(e) => {
                        setEmail(e.target.value);
                      }}
                      type="email"
                      id="email"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                      placeholder="Email"
                      required
                    />
                  </div>
                </div>
                <div>
                  <input
                    value={subject}
                    onInput={(e) => {
                      setSubject(e.target.value);
                    }}
                    type="text"
                    id="subject"
                    className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="sm:col-span-2">
                  <textarea
                    value={message}
                    onInput={(e) => {
                      setMessage(e.target.value);
                    }}
                    id="message"
                    rows="6"
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Message"
                  ></textarea>
                </div>
                <button
                  onClick={handleSubmit}
                  type="button"
                  className="z-0 text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Send Message
                </button>
                <ToastContainer />
              </form>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
