import React, { useState } from "react";
import Logo from "../assets/images/Logo.png";
import { FaLinkedin } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { ImFacebook2 } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { motion } from "framer-motion";
import { FiMapPin } from "react-icons/fi";
import { FaPhone } from "react-icons/fa6";
import { CiMail } from "react-icons/ci";
import { SiTelegram } from "react-icons/si";

import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notify = () =>
  // toast.success(
  //   "Thanks! Your submission is recieved successfully, Will reach you soon",
  //   { position: "top-center" }
  // );
  toast.success("Thanks! for subscribing to our newsletter", {
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    transition: Bounce,
  });

const addDataIntoAirtable = async (obj) => {
  const pat = process.env.REACT_APP_AIRTABLE_PAT;
  const base = process.env.REACT_APP_SAMSINFOSOFT_AIRTABLE_BASE;
  let data = {
    records: [
      {
        fields: obj,
      },
    ],
  };
  const URL = `https://api.airtable.com/v0/${base}/Subcribers`;
  await fetch(URL, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${pat}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      // console.log("Data : ", data);
      notify();
    });
  // console.log("Submitted data : ", submitted);
};

function Footer() {
  const [email, setEmail] = useState("");
  const handleSubmit = () => {
    // console.log("Inside Display: ", email);
    const obj = {
      Email: email,
      Subscribed: true,
    };
    if (email.length) {
      addDataIntoAirtable(obj);
    }
    setEmail("");
  };
  const services = [
    {
      title: "Website Development",
      description: "We have experienced team in website development",
      //   icon: WebDevelopment,
    },
    {
      title: "Application Design",
      description: "We have experienced team in website development",
      //   icon: ApplicationDesign,
    },
    {
      title: "Android Development",
      description: "We have experienced team in website development",
      //   icon: Andriod,
    },
    {
      title: "IOS Development",
      description: "We have experienced team in website development",
      //   icon: isDay ? IOS : IOSWhite,
    },
    {
      title: "Hybrid App Development",
      description: "We have experienced team in website development",
      //   icon: Hybrid,
    },
    {
      title: "Web Hosting",
      description: "We have experienced team in website development",
      //   icon: Hosting,
    },
    {
      title: "SEO Optimization",
      description: "We have experienced team in website development",
      //   icon: SEO,
    },
    {
      title: "Cloud Server",
      description: "We have experienced team in website development",
      //   icon: CloudServer,
    },
    {
      title: "Automations",
      description: "We have experienced team in website development",
      //   icon: Automation,
    },
  ];
  return (
    <div
      key={"footer"}
      id="footer"
      className="w-full"
      style={{ background: "#4F51E7" }}
    >
      <div className={`p-12 md:p-20 bg-black bg-opacity-50`}>
        <div className="grid grid-col-1 md:grid-cols-4 gap-12 text-white">
          <div className="grid grid-cols-1 gap-8">
            <div className="flex justify-evenly items-center">
              <img src={Logo} alt="logo" width={50} />
              <h1 className="font-bold text-3xl uppercase">SAMS Infosoft</h1>
            </div>
            <h1>
              Decription Decription Decription Decription Decription Decription
              Decription Decription Decription Decription Decription Decription
              Decription Decription{" "}
            </h1>
            <div className="flex flex-col justify-center items-center">
              <h1 className="text-[#EFB433] text-2xl font-semibold">
                Follow Us
              </h1>
              <div className={`flex mt-4 text-white`}>
                <motion.a className="mr-4" whileHover={{ scale: 1.5 }}>
                  <FaLinkedin size={30} />
                </motion.a>
                <motion.a className="mr-4" whileHover={{ scale: 1.5 }}>
                  <ImFacebook2 size={30} />
                </motion.a>
                <motion.a
                  className="mr-4"
                  href="https://www.instagram.com/samsinfosoft/"
                  whileHover={{ scale: 1.5 }}
                >
                  <BsInstagram size={30} />
                </motion.a>
                <motion.a className="mr-4" whileHover={{ scale: 1.5 }}>
                  <MdEmail size={30} />
                </motion.a>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8">
            <h1 className="text-[#EFB433] text-3xl font-semibold">Services</h1>
            <div>
              {services.map((service, index) => {
                return (
                  <motion.p
                    key={index}
                    whileHover={{ scale: 1.5, color: "#EFB433", speed: 100 }}
                    className="text-lg hover:font-semibold"
                  >
                    {service.title}
                  </motion.p>
                );
              })}
            </div>
          </div>
          <div className="z-0 grid grid-cols-1 gap-8">
            <h1 className="text-[#EFB433] text-3xl font-semibold">
              Quick Links
            </h1>
            <div className="flex flex-col justify-start">
              <motion.a
                whileHover={{ scale: 1.5 }}
                href="/"
                className="text-lg hover:font-semibold hover:text-[#EFB433]"
              >
                Home
              </motion.a>
              <motion.a
                whileHover={{ scale: 1.5 }}
                href="#features"
                className="text-lg hover:font-semibold hover:text-[#EFB433]"
              >
                About
              </motion.a>
              <motion.a
                whileHover={{ scale: 1.5 }}
                href="#services"
                className="text-lg hover:font-semibold hover:text-[#EFB433]"
              >
                Services
              </motion.a>
              <motion.a
                whileHover={{ scale: 1.5 }}
                href="#technologies"
                className="text-lg hover:font-semibold hover:text-[#EFB433]"
              >
                Technologies
              </motion.a>
              <motion.a
                whileHover={{ scale: 1.5 }}
                href="#contact"
                className="text-lg hover:font-semibold hover:text-[#EFB433]"
              >
                Contact
              </motion.a>
            </div>
          </div>
          <div className="z-0 grid grid-cols-1 gap-8">
            <h1 className="text-[#EFB433] text-3xl font-semibold">Contact</h1>
            <div className="flex justify-evenly items-center">
              <FiMapPin size={20} />
              <div id="address" className="text-sm">
                <p>123/3, Sector-K, Green Park Colony</p>
                <p>Dhar Road, Indore, Madhya Pradesh</p>
                <p> India - 452002</p>
              </div>
            </div>
            <div className="flex justify-evenly items-center">
              <FaPhone size={20} />
              <div id="address" className="text-sm">
                <p>+91 8982413107</p>
              </div>
            </div>
            <div className="flex justify-evenly items-center">
              <CiMail size={20} />
              <a href="mailto:info@samsinfosoft.com" className="z-0">
                <p className="z-0">info@samsinfosoft.com</p>
              </a>
            </div>
            <div className="flex flex-col justify-center items-center">
              <h1 className="text-[#EFB433] text-2xl font-semibold">
                Newsletter
              </h1>
              <p>
                Don't miss to subscribe to our new feeds, kindly fill the form
                below.
              </p>
              <div className="relative w-full mt-2">
                <input
                  type="email"
                  id="email"
                  className="block w-full p-4  text-sm text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Email Address"
                  value={email}
                  onChange={() => {}}
                  onInput={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                />
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="text-white absolute end-2.5 bottom-2.5"
                >
                  <SiTelegram color="blue" size={35} />
                </button>
              </div>
              {/* <div className={`flex mt-4 text-white`}>
                <motion.a className="mr-4" whileHover={{ scale: 1.5 }}>
                  <FaLinkedin size={30} />
                </motion.a>
                <motion.a className="mr-4" whileHover={{ scale: 1.5 }}>
                  <ImFacebook2 size={30} />
                </motion.a>
                <motion.a
                  className="mr-4"
                  href="https://www.instagram.com/samsinfosoft/"
                  whileHover={{ scale: 1.5 }}
                >
                  <BsInstagram size={30} />
                </motion.a>
                <motion.a className="mr-4" whileHover={{ scale: 1.5 }}>
                  <MdEmail size={30} />
                </motion.a>
              </div> */}
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
