import Particles, { initParticlesEngine } from "@tsparticles/react";
import { useEffect, useMemo, useState } from "react";
// import { loadAll } from "@/tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
import { useSelector } from "react-redux";
import Navbar from "./Navbar";
import Introduction from "./Introduction";
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.

const styles = {
  container: {
    position: "relative",
    width: "100vw",
    // height: "100vh",
    // overflow: "hidden",
  },
};

const ParticlesComponent = (props) => {
  // eslint-disable-next-line
  const [init, setInit] = useState(false);
  const [bgColor, setBgColor] = useState(
    "linear-gradient(to right, #9496f6, #4F51E7)"
  );
  const isDay = useSelector((state) => state.colorTheme.day);
  useEffect(() => {
    let color = isDay
      ? "linear-gradient(to right, #9496f6, #4F51E7)"
      : "#000000";
    setBgColor(color);
  }, [isDay]);
  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = async (container) => {
    // console.log(container);
    await loadFull(container);
  };

  const options = useMemo(
    () => ({
      background: {
        color: {
          value: "transparent",
        },
      },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "repulse",
          },
          onHover: {
            enable: true,
            mode: "grab",
          },
        },
        modes: {
          push: {
            distance: 200,
            duration: 100,
          },
          grab: {
            distance: 150,
          },
        },
      },
      particles: {
        color: {
          value: "#FFFFFF",
        },
        links: {
          color: "#FFFFFF",
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: true,
          speed: 5,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 250,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: "star",
        },
        size: {
          value: { min: 1, max: 3 },
        },
      },
      detectRetina: true,
    }),
    []
  );

  return (
    <div
      style={{
        ...styles.container,
        background: bgColor,
      }}
    >
      <Particles id={"tsparticles"} init={particlesLoaded} options={options} />
      <Navbar />
      <Introduction />
    </div>
  );
};

export default ParticlesComponent;
