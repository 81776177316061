import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  day: true,
};

export const colorThemeSlicer = createSlice({
  name: "colorTheme",
  initialState,
  reducers: {
    changeColorTheme: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.day = !state.day;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeColorTheme } = colorThemeSlicer.actions;

export default colorThemeSlicer.reducer;
