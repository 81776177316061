import React from "react";
import Experience from "../../assets/icons/features/satisfaction.png";
import Products from "../../assets/icons/features/new-product.png";
import Approach from "../../assets/icons/features/proactive.png";
import Pricing from "../../assets/icons/features/price-tag.png";
import Delivery from "../../assets/icons/features/fast-delivery.png";
import Support from "../../assets/icons/features/customer-service.png";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import Team from "../../assets/images/team.jpg";

function FeatureItems() {
  const isDay = useSelector((state) => state.colorTheme.day);
  const features1 = [
    {
      title: "Experience",
      description: "ABC",
      icon: Experience,
    },
    {
      title: "Products",
      description: "ABC",
      icon: Products,
    },
    {
      title: "Approach",
      description: "ABC",
      icon: Approach,
    },
  ];
  const features2 = [
    {
      title: "Pricing",
      description: "ABC",
      icon: Pricing,
    },
    {
      title: "Delivery",
      description: "ABC",
      icon: Delivery,
    },
    {
      title: "Support",
      description: "ABC",
      icon: Support,
    },
  ];
  return (
    <div className="z-0 w-full p-4 grid grid-cols-1 md:px-12 md:grid-cols-3 gap-8">
      <div className="z-0">
        {features1.map((service, index) => {
          return (
            <motion.div
              key={index}
              initial={{
                x: -1000,
              }}
              animate={{ x: 0 }}
              transition={{ duration: 0.5, delay: (index + 1) * 0.5 }}
              className={`my-8 z-0 shadow-md rounded-md ${
                isDay ? "shadow-gray-300" : "shadow-[#4F51E7]"
              }`}
            >
              <div
                className={`hover:scale-110 duration-200 z-0 w-full h-full ${
                  isDay ? "bg-white" : "bg-black"
                } flex justify-center flex-col items-center p-8 rounded-md ${
                  isDay ? "shadow-gray-300" : "shadow-[#4F51E7]"
                } shadow-inner`}
              >
                <img
                  className="z-0"
                  src={service.icon}
                  alt={service.title}
                  width={50}
                  style={{ aspectRatio: 1 }}
                />
                <h1 className="z-0 text-2xl mt-4 font-semibold">
                  {service.title}
                </h1>
                <p
                  className={`z-0 ${isDay ? "text-black" : "text-white"} mt-2`}
                >
                  {service.description}
                </p>
              </div>
            </motion.div>
          );
        })}
      </div>
      <motion.div className="z-0 flex items-center">
        <div
          className={`hover:scale-110 duration-200 rounded-full shadow-lg ${
            isDay ? "shadow-gray-300" : "shadow-[#4F51E7]"
          }`}
        >
          <img src={Team} className="rounded-full" alt="team" />
        </div>
      </motion.div>

      <div className="z-0">
        {features2.map((service, index) => {
          return (
            <motion.div
              key={index}
              initial={{
                x: 1000,
              }}
              animate={{ x: 0 }}
              transition={{ duration: 0.5, delay: (index + 1) * 0.5 }}
              className={`my-8 z-0 shadow-md rounded-md ${
                isDay ? "bg-white" : "bg-black"
              } ${isDay ? "shadow-gray-300" : "shadow-[#4F51E7]"}`}
            >
              <div
                className={`hover:scale-110 duration-200 z-0 w-full h-full ${
                  isDay ? "bg-white" : "bg-black"
                } flex justify-center flex-col items-center p-8 rounded-md ${
                  isDay ? "shadow-gray-300" : "shadow-[#4F51E7]"
                } shadow-inner`}
              >
                <img
                  className="z-0"
                  src={service.icon}
                  alt={service.title}
                  width={50}
                  style={{ aspectRatio: 1 }}
                />
                <h1 className="z-0 text-2xl mt-4 font-semibold">
                  {service.title}
                </h1>
                <p
                  className={`z-0 ${isDay ? "text-black" : "text-white"} mt-2`}
                >
                  {service.description}
                </p>
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
}

export default FeatureItems;
