import "./App.css";
import ParticlesComponent from "./components/ParticlesComponent";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    window.scroll({ behavior: "smooth" });
  }, []);
  return (
    <div className="App">
      <Helmet>
        <link
          rel="icon"
          type="image/png"
          href="/public/favicon.ico"
          sizes="16x16"
        />
        <link
          rel="shortcut icon"
          type="image/png"
          href="/public/favicon.ico"
          sizes="16x16"
        />
      </Helmet>
      <ParticlesComponent id={"particles"} />
      <Routes>
        <Route exact path="/" element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
