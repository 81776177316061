import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selector: "all-prayer-time",
};

export const sidebarSubItemSlicer = createSlice({
  name: "sub-item-selector",
  initialState,
  reducers: {
    changeSidebarSubItemActiveCell: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.selector = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeSidebarSubItemActiveCell } = sidebarSubItemSlicer.actions;

export default sidebarSubItemSlicer.reducer;
