import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  message: "",
  type: "",
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    triggerSnackbar: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = !state.value;
      state.show = action.payload.show;
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
  },
});

// Action creators are generated for each case reducer function
export const { triggerSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
