import React from "react";
import clients from "../assets/icons/stats/happy-clients.svg";
import hours from "../assets/icons/stats/hours-support.svg";
import projects from "../assets/icons/stats/complete-projects.svg";
import { useSelector } from "react-redux";

function Stats() {
  const isDay = useSelector((state) => state.colorTheme.day);
  const items = [
    {
      logo: clients,
      title: "Happy Clients",
      desc: "10+",
    },
    {
      logo: projects,
      title: "Completed Projects",
      desc: "20+",
    },
    {
      logo: hours,
      title: "Hours of Efforts",
      desc: "1000+",
    },
  ];
  return (
    <div id="stats" className="w-full" style={{ background: "#4F51E7" }}>
      <div
        className={`p-12 md:p-20 ${
          isDay ? "bg-white bg-opacity-20" : "bg-black bg-opacity-50"
        }`}
      >
        <div className="grid grid-col-1 md:grid-cols-3 gap-4">
          {items.map((item, index) => {
            return (
              <div
                key={index}
                className="flex justify-center items-center flex-col text-white p-8 w-full"
              >
                <img src={item.logo} alt={item.title} />
                <h1 className="text-white mt-4 font-semibold text-xl">
                  {item.title}
                </h1>
                <p className="mt-2">{item.desc}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Stats;
