import React from "react";
import gif from "../assets/gif/Discussion.gif";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

function LetsDiscuss() {
  const isDay = useSelector((state) => state.colorTheme.day);

  return (
    <div id="stats" className="z-0 w-full" style={{ background: "#4F51E7" }}>
      <div
        className={`z-0 p-12 md:p-20 ${
          isDay ? "bg-white bg-opacity-20" : "bg-black bg-opacity-50"
        }`}
      >
        <div className="z-0 flex-wrap md:flex items-center justify-around">
          <div className="bg-white z-0 rounded-full">
            <img
              src={gif}
              alt="discussion"
              className="z-0 rounded-full"
              width={300}
            />
          </div>
          <div className="z-0 text-white py-8 md:p-8">
            <h1 className="font-bold text-4xl mb-4">
              Let's Discuss your Projects
            </h1>
            <p className="font-semibold text-xl">
              We pride ourselves with our ability to perform and deliver
              results. Use the form below to discuss your project needs with our
              team, we will get back asap
            </p>
            <motion.div className="z-0 flex mt-4 justify-center">
              <a href="#contact" className="z-0">
                <button
                  type="button"
                  className="z-0 mt-8 text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Contact Us
                </button>
              </a>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LetsDiscuss;
