import React from "react";
import Marquee from "react-fast-marquee";
import BalgreenMosque from "../assets/icons/clients/balgreen_mosque.png";
import Onports from "../assets/icons/clients/onports_logo.png";
import Ellow from "../assets/icons/clients/ellow.png";
import NatureLaminates from "../assets/icons/clients/nature-laminates.png";
import SA from "../assets/icons/clients/s&a.png";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

function Clients() {
  const isDay = useSelector((state) => state.colorTheme.day);

  const clients = [
    {
      logo: BalgreenMosque,
      name: "Balgreen Mosque",
    },
    {
      logo: SA,
      name: "Seh & Alexander Advisors",
    },
    {
      logo: Onports,
      name: "Onport Pvt. Ltd.",
    },
    {
      logo: Ellow,
      name: "Ellow.io",
    },
    {
      logo: NatureLaminates,
      name: "Nature Laminates",
    },
  ];
  return (
    <div
      className="w-full px-12 py-8 md:px-20"
      style={{ background: isDay ? "white" : "black" }}
    >
      <div
        className={`${
          isDay ? "bg-white bg-opacity-20" : "bg-black bg-opacity-50"
        }`}
      >
        <h1 className={`text-3xl md:text-4xl font-semibold text-primary`}>
          Our Clients
        </h1>
        <Marquee className="py-12">
          {clients.map((client, index) => {
            return (
              <motion.div
                key={index}
                whileHover={{ scale: 1.1 }}
                className="flex items-center justify-center rounded-md bg-white mr-20 p-8 shadow-inner shadow-gray-500"
              >
                <img
                  src={client.logo}
                  alt={client.name}
                  width={70}
                  style={{ aspectRatio: 1 }}
                  className="mr-2"
                />
                <h1 className="font-semibold">{client.name}</h1>
              </motion.div>
            );
          })}
        </Marquee>
      </div>
    </div>
  );
}

export default Clients;
