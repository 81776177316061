import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selector: "prayer-time",
};

export const sidebarSlicer = createSlice({
  name: "selector",
  initialState,
  reducers: {
    changeSidebarActiveCell: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.selector = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeSidebarActiveCell } = sidebarSlicer.actions;

export default sidebarSlicer.reducer;
