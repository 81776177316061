import { configureStore } from "@reduxjs/toolkit";
import loaderReducer from "./reducers/loaderSlicer";
import snackbarReducer from "./reducers/snackbarSlicer";
import sidebarActiveReducer from "./reducers/sidebarActiveSlicer";
import leftNavbarReducer from "./reducers/leftNavbarSlicer";
import sidebarSubItemActiveReducer from "./reducers/sidebarSubItemActiveSlicer";
import colorThemeReducer from "./reducers/colorThemeSlicer";

export const store = configureStore({
  reducer: {
    loader: loaderReducer,
    snackbar: snackbarReducer,
    sidebarActive: sidebarActiveReducer,
    sidebarSubItemActive: sidebarSubItemActiveReducer,
    leftNavbar: leftNavbarReducer,
    colorTheme: colorThemeReducer,
  },
});
