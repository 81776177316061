import React from "react";
import { Helmet } from "react-helmet-async";
import Services from "./Services/Services";
import Features from "./Features/Features";
import Technologies from "./Technologies/Technologies";
import Stats from "./Stats";
import Clients from "./ Clients";
import LetsDiscuss from "./LetsDiscuss";
import ContactUs from "./ContactUs";

function Home() {
  return (
    <div>
      <Helmet>
        <title>SAMS Infosoft - Home</title>
      </Helmet>
      <Services />
      <Features />
      <Technologies />
      <Stats />
      <Clients />
      <LetsDiscuss />
      <ContactUs />
    </div>
  );
}

export default Home;
