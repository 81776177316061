import React from "react";
import { useSelector } from "react-redux";
import TechnologiesItems from "./TechnologiesItems";

function Technologies() {
  const isDay = useSelector((state) => state.colorTheme.day);
  return (
    <div
      id="technologies"
      className={`z-0 w-full p-8 ${
        isDay ? "bg-white" : "bg-black"
      } text-primary`}
    >
      <h1 className={`text-3xl md:text-4xl font-semibold my-4`}>
        Technologies
      </h1>
      <TechnologiesItems />
    </div>
  );
}

export default Technologies;
