import React from "react";
import Mockup from "../assets/images/mockup.png";
import { FaLinkedin } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { ImFacebook2 } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

function Introduction() {
  const isDay = useSelector((state) => state.colorTheme.day);

  return (
    <div className="md:h-screen grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-16 md:p-16 px-8 py-12 z-0">
      <div className="flex justify-center items-center">
        <motion.div
          className="z-0"
          initial={{ x: -1000 }}
          animate={{ x: 0 }}
          exit={{ x: -1000 }}
          transition={{ duration: 1.0, type: "spring" }}
        >
          <img src={Mockup} alt="Mockup" className="z-0" />
        </motion.div>
      </div>
      <motion.div
        initial={{ x: 1000 }}
        animate={{ x: 0 }}
        transition={{ duration: 1.0, type: "spring" }}
        className="flex flex-col justify-center text-left text-white px-2"
      >
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, type: "spring", delay: 1 }}
          className="uppercase z-0 text-left font-bold mb-4"
        >
          <h1 className="text-2xl md:text-3xl">Web and Mobile App</h1>
          <h1 className="text-4xl lg:text-6xl">Development</h1>
        </motion.div>
        <motion.h1
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, type: "spring", delay: 1.5 }}
          className="z-0 md:pr-16 font-semibold text-xl md:text-2xl"
        >
          We provides extensive mobile app development services for individual
          and corporate clients.
        </motion.h1>
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, type: "spring", delay: 2.0 }}
          className={`flex mt-4 ${isDay ? "text-black" : "text-white"}`}
        >
          <motion.a className="mr-4" whileHover={{ scale: 1.5 }}>
            <FaLinkedin size={30} />
          </motion.a>
          <motion.a className="mr-4" whileHover={{ scale: 1.5 }}>
            <ImFacebook2 size={30} />
          </motion.a>
          <motion.a
            className="mr-4"
            href="https://www.instagram.com/samsinfosoft/"
            whileHover={{ scale: 1.5 }}
          >
            <BsInstagram size={30} />
          </motion.a>
          <motion.a className="mr-4" whileHover={{ scale: 1.5 }}>
            <MdEmail size={30} />
          </motion.a>
        </motion.div>
        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, type: "spring", delay: 2.5 }}
          className="z-0 flex mt-4 justify-center md:justify-start"
        >
          <a href="#contact">
            <button
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Get Quote
            </button>
          </a>
          <a href="#services">
            <button
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Get Started
            </button>
          </a>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default Introduction;
