import React from "react";
import { motion } from "framer-motion";

import pythonlogo from "../../assets/icons/technologies/python.png";
import nodejslogo from "../../assets/icons/technologies/nodejs.webp";
import Angularjslogo from "../../assets/icons/technologies/angular.png";
import reactlogo from "../../assets/icons/technologies/react.png";
import js from "../../assets/icons/technologies/javascript.svg";
import airtable from "../../assets/icons/technologies/airtable.svg";
import aws from "../../assets/icons/technologies/aws.svg";
import django from "../../assets/icons/technologies/django.webp";
import express from "../../assets/icons/technologies/Express.svg";
import firebase from "../../assets/icons/technologies/firebase.svg";
import flask from "../../assets/icons/technologies/flask.webp";
import flutter from "../../assets/icons/technologies/flutter.webp";
import gcp from "../../assets/icons/technologies/google-cloud.svg";
import mongo from "../../assets/icons/technologies/logos_mongodb.svg";
import mysql from "../../assets/icons/technologies/mysql.svg";
import razorpay from "../../assets/icons/technologies/razorpay.svg";
import postgresql from "../../assets/icons/technologies/postgresql.svg";
import socketio from "../../assets/icons/technologies/socket.svg";
import tailwind from "../../assets/icons/technologies/tailwind.svg";
import zapier from "../../assets/icons/technologies/zapier.webp";
import { useSelector } from "react-redux";

function TechnologiesItems() {
  const isDay = useSelector((state) => state.colorTheme.day);

  const technologies = [
    { name: "NodeJS", logo: nodejslogo, alt: "Node.js logo" },
    { name: "Javascript", logo: js, alt: "Node.js logo" },
    { name: "Airtable", logo: airtable, alt: "Node.js logo" },
    { name: "Zapier", logo: zapier, alt: "Node.js logo" },
    { name: "Python", logo: pythonlogo, alt: "Python logo" },
    { name: "AngularJS", logo: Angularjslogo, alt: "AngularJS logo" },
    { name: "ReactJS", logo: reactlogo, alt: "React logo" },
    { name: "AWS", logo: aws, alt: "React logo" },
    { name: "GCP", logo: gcp, alt: "React logo" },
    { name: "Django", logo: django, alt: "React logo" },
    { name: "ExpressJS", logo: express, alt: "React logo" },
    { name: "Firebase", logo: firebase, alt: "React logo" },
    { name: "Flutter", logo: flutter, alt: "React logo" },
    { name: "Flask", logo: flask, alt: "React logo" },
    { name: "PostgreSQL", logo: postgresql, alt: "React logo" },
    { name: "MySQL", logo: mysql, alt: "React logo" },
    { name: "MongoDB", logo: mongo, alt: "React logo" },
    { name: "RazorPay", logo: razorpay, alt: "React logo" },
    { name: "Tailwind", logo: tailwind, alt: "React logo" },
    { name: "Socket.IO", logo: socketio, alt: "React logo" },
  ];
  return (
    <div className="z-0 w-full p-4 grid grid-cols-2 md:px-12 sm:grid-cols-3 md:grid-cols-4 gap-8">
      {technologies.map((technology, index) => {
        return (
          <motion.div
            key={index}
            // whileHover={{ scale: 1.1 }}
            // initial={{ scale: 0 }}
            // animate={{ scale: 1 }}
            // transition={{ duration: 0.5 }}
            whileHover={{ rotate: 5 }}
            className={`z-0 shadow-md rounded-md ${
              isDay ? "shadow-gray-300" : "shadow-[#4F51E7]"
            }`}
          >
            <motion.div
              whileHover={{ scale: 1.1 }}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div
                className={`z-0 w-full h-full ${
                  isDay ? "bg-white" : "bg-black"
                } flex justify-center flex-col items-center p-4 md:p-8 rounded-md ${
                  isDay ? "shadow-gray-300" : "shadow-[#4F51E7]"
                } shadow-inner`}
              >
                <img
                  className="z-0 bg-white rounded-lg"
                  src={technology.logo}
                  alt={technology.name}
                  width={50}
                  style={{ aspectRatio: 1 }}
                />
                <h1 className="z-0 md:text-2xl mt-4 font-semibold">
                  {technology.name}
                </h1>
              </div>
            </motion.div>
          </motion.div>
        );
      })}
    </div>
  );
}

export default TechnologiesItems;
